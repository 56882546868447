import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "@fontsource/open-sans"

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Open Sans, sans-serif, serif",
  maxWidth: 600,
  marginLeft: "auto",
  marginRight: "auto",
  padding:"20px"
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}


const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial/",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
  {
    text: "How to Guides",
    url: "https://www.gatsbyjs.com/docs/how-to/",
    description:
      "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    color: "#1099A8",
  },
  {
    text: "Reference Guides",
    url: "https://www.gatsbyjs.com/docs/reference/",
    description:
      "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
    color: "#BC027F",
  },
  {
    text: "Conceptual Guides",
    url: "https://www.gatsbyjs.com/docs/conceptual/",
    description:
      "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
    color: "#0D96F2",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
    description:
      "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Build and Host",
    url: "https://www.gatsbyjs.com/cloud",
    badge: true,
    description:
      "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
    color: "#663399",
  },
]

const IndexPage = () => {
  return (
    <main style={pageStyles}>
    <div>
    <StaticImage style={{maxWidth:"300px", marginLeft:"auto", marginRight:"auto", display:"block"}}src="./../images/institution_3.png" alt="Logo" />
    </div>
    <div>
    <h3 style={{paddingTop:"20px"}}>Our Story</h3>
      <p> This is going to be some super fun and creative story about how <a href="https://www.talaschlossberg.com/">Tala</a>,  <a href="https://www.adamiscoe.com/">Adam</a>, and  <a href="https://www.brettbejcek.com/">Brett</a> battle 
      the biggest boss of them all: cultivating community in NYC. ~~Yadda yadda yadda ~~ talk talk talk talk. None of this
      is designed or anything, just here to fill the space. wooooo.</p>
    </div>
    <div>
    <h3>Get involved</h3>
      <p> You should join us and be our friend forever. this isn't a cult don't worry. no cult here. none whatsoever. mhmmmm</p>
    </div>
      <div>
    <h3>Some other fun section</h3>
      <p>Did you know that Maseru is the capital of Lesotho? Well now you do buckaroo. But I bet you still want another fun fact. People always want more. Here's an image that I drew on my iPad. I liked the shape (it kind of reminds me of a ghost trying to give me a high five) of so I just stole parts of it's curves and worked them in around here in places:</p>
      <StaticImage style={{maxWidth:"300px", marginLeft:"auto", marginRight:"auto", display:"block"}}src="./../images/institution_icon.png" alt="Logo" />

    </div>
    <div style={{marginBottom:"-28px"}}>
    <StaticImage src="./../images/footer.png" alt="Footer" />
    <div style={{background:"black", color:"white", paddingLeft:"10px"}}><br/><br/>AYOO I made this fun footer which has curves and stuff.<br/><br/></div>
    </div>
    </main>
  )
}

export default IndexPage

export const Head = () => <title>The Creative Institution</title>
